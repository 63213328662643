import React, { Fragment, useState, useEffect } from "react";
import Button from "../UI/Button/Button";
// import Backdrop from "../UI/Backdrop/Backdrop";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Refresh_icon from "../../assets/icons/refresh-icon.svg";
import "./Home.css";
import moment from "moment";
import LiveshotAndVenueNameModal from "../liveshotAndVenueNameModal/LiveshotAndVenueNameModal";
import XApiKeyModal from "../xApiKeyModal/XApiKeyModal";

const Home = (props) => {
  const {
    onGetLiveShotInfo,
    liveShotInfo,
    userList,
    onFetchUserList,
    liveshotNamesList,
    onFetchLiveshotNameSuggestions,
    onFetchStudioTruckList,
    studioTruckList,
    location,
    infoFailure,
  } = props;
  const [searchOptions, setSearchOptions] = useState(null);
  const [xApiKeyParam, setXApiKeyParam] = useState(null);
  const [timeParam, setTimeParam] = useState(null);
  const [roomParam, setRoomParam] = useState(null);
  const [perPageParam, setPerPageParam] = useState(null);
  const [abstractLiveshotNameParam, setAbstractLiveshotNameParam] =
    useState(null);
  const [stIdParam, setStIdParam] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [showXapiModal, setShowXapiModal] = useState(false);
  const [title, setTitle] = useState("");
  const [displayDate, setDisplayDate] = useState("");

  let apiKey = localStorage.getItem("x-api-key");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const allParams = {};
    for (let param of query.entries()) {
      allParams[param[0]] = param[1];
    }
    setSearchOptions(allParams);
  }, [location]);

  useEffect(() => {
    if (searchOptions) {
      let xApiKey = null;
      if (apiKey) {
        xApiKey = apiKey;
      } else if (searchOptions.xApiKey) {
        xApiKey = searchOptions.xApiKey;
      }
      setXApiKeyParam(xApiKey);
      onFetchUserList(xApiKey);
      onFetchLiveshotNameSuggestions(xApiKey);
      onFetchStudioTruckList(xApiKey);
    }
  }, [
    onFetchUserList,
    apiKey,
    searchOptions,
    onFetchLiveshotNameSuggestions,
    onFetchStudioTruckList,
  ]);

  useEffect(() => {
    if (infoFailure === true) {
      setXApiKeyParam(null);
      localStorage.removeItem("x-api-key");
      if (searchOptions.xApiKey) {
        onDeleteQueryStringParameter("xApiKey");
      }
    }
  }, [infoFailure, searchOptions]);

  const onDeleteQueryStringParameter = (key) => {
    let query = window.location.search;
    let slicedQuery = query.substring(1);
    let updatedQuery =
      slicedQuery.substring(0, 0) + "&" + slicedQuery.substring(0);
    var rege = new RegExp("&" + key + "(=[^&]*)?|^" + key + "(=[^&]*)?");
    if (updatedQuery.match(rege)) {
      let newurl = updatedQuery.replace(rege, "");
      let queryString = newurl.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryString;
    }
  };

  useEffect(() => {
    if (searchOptions) {
      let time = null;
      let room = null;
      let perPage = null;
      let xApiKey = null;
      let abstractLiveshotName = null;
      let stId = null;
      if (searchOptions.xApiKey) {
        xApiKey = searchOptions.xApiKey;
        setXApiKeyParam(xApiKey);
      }
      if (searchOptions.time) {
        time = searchOptions.time;
        setTimeParam(time);
        let displayTime = time ? moment(time).format("LL") : "";
        setDisplayDate(displayTime);
      } else {
        let time = new Date();
        let displayTime = time ? moment(time).format("LL") : "";
        setDisplayDate(displayTime);
      }
      if (searchOptions.perPage) {
        perPage = searchOptions.perPage;
        setPerPageParam(perPage);
      }
      if (searchOptions.room) {
        room = searchOptions.room;
        setRoomParam(room);
      }
      if (searchOptions.abstractLiveshotName) {
        abstractLiveshotName = searchOptions.abstractLiveshotName;
        setAbstractLiveshotNameParam(abstractLiveshotName);
      }
      if (searchOptions.stId) {
        stId = searchOptions.stId;
        setStIdParam(stId);
      }
    }
  }, [searchOptions, userList]);

  // useEffect(() => {
  //   if(xApiKeyParam || apiKey){
  //     c(false);
  //   } else {
  //     setShowXapiModal(true);
  //   }
  // },[xApiKeyParam,apiKey]);

  useEffect(() => {
    if (xApiKeyParam) {
      if (!searchOptions.xApiKey) {
        updateQueryStringParameter("xApiKey", xApiKeyParam);
      }
    }
    if (abstractLiveshotNameParam || stIdParam) {
      if (!searchOptions.abstractLiveshotName && abstractLiveshotNameParam) {
        updateQueryStringParameter(
          "abstractLiveshotName",
          abstractLiveshotNameParam
        );
      }
      if (!searchOptions.stId && stIdParam) {
        updateQueryStringParameter("stId", stIdParam);
      }
      if (userList.length > 0 && (xApiKeyParam || apiKey)) {
        onGetLiveShotInfo(
          xApiKeyParam,
          timeParam,
          roomParam,
          perPageParam,
          userList,
          abstractLiveshotNameParam,
          stIdParam
        );
      }
      setShowModal(false);
    }
  }, [
    searchOptions,
    onGetLiveShotInfo,
    apiKey,
    userList,
    xApiKeyParam,
    timeParam,
    roomParam,
    perPageParam,
    abstractLiveshotNameParam,
    stIdParam,
  ]);

  useEffect(() => {
    const stId = Number(stIdParam);
    const studioName = studioTruckList
      .filter((list) => list.stId === stId && list.softDelete === false)
      .map((list) => {
        return list.studioName;
      });
    let venueName;
    if (studioName.length > 0) {
      venueName = studioName[0];
    } else {
      venueName = stIdParam;
    }
    if (abstractLiveshotNameParam && stIdParam) {
      if (studioName) {
        setTitle(abstractLiveshotNameParam + " - " + venueName);
      }
    }
    if (abstractLiveshotNameParam && !stIdParam) {
      setTitle(abstractLiveshotNameParam);
    }
    if (stIdParam && !abstractLiveshotNameParam) {
      setTitle(venueName);
    }
  }, [stIdParam, studioTruckList, abstractLiveshotNameParam]);

  const onRefreshSearch = () => {
    if (abstractLiveshotNameParam || stIdParam) {
      onGetLiveShotInfo(
        xApiKeyParam,
        timeParam,
        roomParam,
        perPageParam,
        userList,
        abstractLiveshotNameParam,
        stIdParam
      );
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const updateQueryStringParameter = (key, value) => {
    let query = window.location.search;
    let uri = query.substring(1);
    let updatedQuery = uri.substring(0, 0) + "&" + uri.substring(0);
    var re = new RegExp("&" + key + "(=[^&]*)?|^" + key + "(=[^&]*)?");
    var separator = "&";
    if (updatedQuery.match(re)) {
      let queryString = updatedQuery.replace(re, separator + key + "=" + value);
      let query = queryString.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query;
    } else {
      let queryString = updatedQuery + separator + key + "=" + value;
      let query = queryString.substring(1);
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query;
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  let liveshotNameModal = null;
  if (showModal) {
    if (liveshotNamesList.length > 0) {
      liveshotNameModal = (
        <Fragment>
          {/* <Backdrop show={showModal} clicked={closeModal} /> */}
          <LiveshotAndVenueNameModal
            closeModal={closeModal}
            liveshotNamesList={liveshotNamesList}
            venueList={studioTruckList}
            liveShotName={(name) => setAbstractLiveshotNameParam(name)}
            stId={(stId) => setStIdParam(stId)}
          />
        </Fragment>
      );
    }
  }

  const closeXapiModal = () => {
    setShowXapiModal(false);
  };

  let xApiModal = null;
  if (showXapiModal) {
    xApiModal = (
      <Fragment>
        {/* <Backdrop show={showXapiModal} clicked={closeXapiModal} /> */}
        <XApiKeyModal
          closeXapiModal={closeXapiModal}
          xApiKey={(xApi) => setXApiKeyParam(xApi)}
        />
      </Fragment>
    );
  }

  let liveshotDetail = null;
  if (liveShotInfo.length > 0) {
    liveshotDetail = liveShotInfo.map((liveShot) => (
      <div
        key={liveShot.id}
        className={
          liveShot.ready === "PCR"
            ? "text-blue liveshot-item"
            : liveShot.ready === "AIR"
            ? "text-green liveshot-item"
            : liveShot.ready === "CANC"
            ? "text-gray liveshot-item"
            : liveShot.ready === "CLEAR"
            ? "text-orange liveshot-item"
            : liveShot.ready === "MTC"
            ? "text-white liveshot-item"
            : " text-white liveshot-item"
        }
      >
        <div className="row liveshot">
          <div className="col-3 first">
            <div className="roomCode">{liveShot.roomCode}</div>
          </div>
          <div className="col-9">
            <div className="showName">{liveShot.showName}</div>
          </div>
        </div>
        <div className="row liveshot">
          <div className="col-3 first">
            <div className="hitTime">{liveShot.hitTime}</div>
          </div>
          <div className="col-6">
            <div className="comms">
              {liveShot.commsPhnNumber ? (
                <a
                  href={"callto:" + liveShot.commsPhnNumber}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {liveShot.commsPhnNumber}{" "}
                </a>
              ) : (
                <span>{liveShot.comms}</span>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="ready-cell">{liveShot.ready}</div>
          </div>
        </div>
      </div>
    ));
  } else {
    liveshotDetail = <div className="ready-cell">No Liveshots Available</div>;
  }
  return (
    <Fragment>
      {liveshotNameModal}
      {xApiModal}
      <div className="Liveshot_container">
        <div className="refresh-btn">
          <Button onClick={onRefreshSearch} className="refresh-btn">
            <img src={Refresh_icon} alt="refreshIcon" />
            Refresh
          </Button>
          {/* {showLoader ? <i className="fas fa-spinner fa-spin fa-2x"></i> : null} */}
        </div>
        <h5>
          {title} - {displayDate}
        </h5>
        <div className="header">
          <div className="col-3 crpath">CR</div>
          <div className="col-9 crpath">Show</div>
        </div>
        <div className="header">
          <div className="col-3 crpath">Hit Time</div>
          <div className="col-6 crpath">Comms</div>
          <div className="col-3 crpath">Ready</div>
        </div>
        <div className="liveshotList">{liveshotDetail}</div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    liveShotInfo: state.liveshotReducer.liveShotInfo,
    userList: state.userInfo.userList,
    liveshotNamesList: state.liveshotReducer.liveshotNamesList,
    studioTruckList: state.studioTruckInfo.studioTruckList,
    infoFailure: state.liveshotReducer.infoFailure,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetLiveShotInfo: (
      xApiKeyParam,
      timeParam,
      roomParam,
      perPageParam,
      userList,
      abstractLiveshotNameParam,
      stIdParam
    ) =>
      dispatch(
        actions.onGetLiveShotInfo(
          xApiKeyParam,
          timeParam,
          roomParam,
          perPageParam,
          userList,
          abstractLiveshotNameParam,
          stIdParam
        )
      ),
    onFetchUserList: (xApiKey) => dispatch(actions.fetchUserList(xApiKey)),
    onFetchLiveshotNameSuggestions: (xApiKey) =>
      dispatch(actions.liveShotNameSuggestions(xApiKey)),
    onFetchStudioTruckList: (xApiKey) =>
      dispatch(actions.fetchStudioTruckList(xApiKey)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

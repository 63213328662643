import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import moment from "moment";
import { splitSignalAndPhone } from "../../store/shared/utility";

export const liveShotInfoSuccess = (res) => {
  let lastUpdated = moment();
  return {
    type: actionTypes.FETCH_LIVESHOT_DATA,
    liveShotInfo: res,
    lastUpdated: lastUpdated,
  };
};

export const liveshotNamesSuccess = (resdata) => {
  return {
    type: actionTypes.LIVE_SHOT_NAMES_SUCCESS,
    liveshotNamesList: resdata,
  };
};

export const liveShotInfoFailure = (resdata) => {
  return {
    type: actionTypes.LIVE_SHOT_INFO_FAILURE,
  };
};

export const onGetLiveShotInfo = (
  xApiKey,
  time,
  room,
  perPage,
  userList,
  abstractLiveshotName,
  stId
) => {
  return async (dispatch) => {
    const queryParams = [];
    if (time) {
      queryParams.push("time=" + encodeURIComponent(time));
    }
    if (room) {
      queryParams.push("room=" + encodeURIComponent(room));
    }
    if (perPage && perPage !== "Default") {
      queryParams.push("perPage=" + encodeURIComponent(perPage));
    }
    if (abstractLiveshotName) {
      queryParams.push(
        "abstractLiveshotName=" + encodeURIComponent(abstractLiveshotName)
      );
    }
    if (stId) {
      queryParams.push("stId=" + encodeURIComponent(stId));
    }
    const queryString = queryParams.join("&");
    await axios
      .get(`/liveshot-api/arrivalBoard/?${queryString}&fullCommDetails=true`, {
        headers: { "x-api-key": xApiKey },
      })
      .then((response) => {
        let mainArray = [];
        for (let key in response.data) {
          const arrivalsInfo = response.data[key];
          let readyText = "-";
          if (arrivalsInfo.readyForAir) {
            readyText = "AIR";
          } else if (arrivalsInfo.canceled) {
            readyText = "CANC";
          } else if (arrivalsInfo.needsMediaTrafficReviewFlag) {
            readyText = "MTC";
          } else if (arrivalsInfo.readyForPcr) {
            readyText = "PCR";
          } else if (arrivalsInfo.clear) {
            readyText = "CLEAR";
          }
          let commsPhnNumber;
          let commsValues = splitSignalAndPhone(arrivalsInfo.comms);
          commsPhnNumber = commsValues[1];
          let windowEndTime, windowStartTime;
          windowEndTime =
            arrivalsInfo.windowEndTime === null
              ? ""
              : arrivalsInfo.windowEndTime;
          windowStartTime =
            arrivalsInfo.windowStartTime === null
              ? ""
              : arrivalsInfo.windowStartTime;
          let updateDateTime, updatedOn, updatedBy;
          if (
            arrivalsInfo.liveshotUpdatedOn &&
            arrivalsInfo.liveshotUpdatedOn !== null
          ) {
            updateDateTime = DateFormater(arrivalsInfo.liveshotUpdatedOn);
            updatedOn = updateDateTime
              ? "Updated On: " + updateDateTime[1] + " " + updateDateTime[0]
              : "Updated On: " + arrivalsInfo.liveshotUpdatedOn;
          } else {
            updatedOn = "Updated On: " + arrivalsInfo.liveshotUpdatedOn;
          }
          if (arrivalsInfo.liveshotUpdatedBy) {
            const userNameList = userList.find(
              (element) =>
                element.sso === parseInt(arrivalsInfo.liveshotUpdatedBy)
            );
            updatedBy =
              userNameList !== undefined
                ? "Updated by: " + userNameList.userName
                : "Updated by: " + arrivalsInfo.liveshotUpdatedBy;
          } else {
            updatedBy = "Updated by: " + arrivalsInfo.liveshotUpdatedBy;
          }
          let updateAbstractDateTime,
            updateAbstractOn,
            updateAbstractBy,
            abstractLiveshotId;
          if (
            arrivalsInfo.abstractLiveshotUpdatedOn &&
            arrivalsInfo.abstractLiveshotUpdatedOn !== null
          ) {
            updateAbstractDateTime = DateFormater(
              arrivalsInfo.abstractLiveshotUpdatedOn
            );
            updateAbstractOn = updateAbstractDateTime
              ? "Updated On: " +
                updateAbstractDateTime[1] +
                " " +
                updateAbstractDateTime[0]
              : "Updated On: " + arrivalsInfo.abstractLiveshotUpdatedOn;
          } else {
            updateAbstractOn =
              "Updated On:" + arrivalsInfo.abstractLiveshotUpdatedOn;
          }
          if (arrivalsInfo.abstractLiveshotUpdatedBy) {
            const userNameList = userList.find(
              (element) =>
                element.sso === parseInt(arrivalsInfo.abstractLiveshotUpdatedBy)
            );
            updateAbstractBy =
              userNameList !== undefined
                ? "Updated by: " + userNameList.userName
                : "Updated by: " + arrivalsInfo.abstractLiveshotUpdatedBy;
          } else {
            updateAbstractBy =
              "Updated by: " + arrivalsInfo.abstractLiveshotUpdatedBy;
          }
          if (arrivalsInfo.abstractLiveshotId) {
            abstractLiveshotId =
              "Abs LS ID: " + arrivalsInfo.abstractLiveshotId;
          }
          mainArray.push({
            ...arrivalsInfo,
            window:
              windowStartTime || windowEndTime
                ? windowStartTime + " - " + windowEndTime
                : "",
            ready: readyText,
            commsPhnNumber: commsPhnNumber,
            liveshotUpdated:
              updatedOn || updatedBy ? updatedOn + ", " + updatedBy : "",
            abstractLiveshotUpdated:
              updateAbstractOn || updateAbstractBy
                ? updateAbstractOn + ", " + updateAbstractBy
                : "",
            abstractLiveshotId: abstractLiveshotId ? abstractLiveshotId : "",
          });
        }
        dispatch(liveShotInfoSuccess(mainArray));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response) {
          if (err.response.status === 403) {
            dispatch(liveShotInfoFailure());
          }
        }
      });
  };
};

export const liveShotNameSuggestions = (xApiKey) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/abstract/names?softDelete=false`, {
        headers: { "x-api-key": xApiKey },
      })
      .then((res) => {
        dispatch(liveshotNamesSuccess(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response) {
          if (err.response.status === 403) {
            dispatch(liveShotInfoFailure());
          }
        }
      });
  };
};

const DateFormater = (date) => {
  let data = [];
  if (date === null) return;
  let dateFormat = null;
  dateFormat = new Date(date);
  let timeValue = dateFormat.toLocaleTimeString(dateFormat, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  let dateValue = moment(dateFormat).format("ddd MMM Do");
  data.push(timeValue);
  data.push(dateValue);
  return data;
};

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  liveShotInfo: [],
  liveshotNamesList: "",
  infoFailure: false,
};

const liveShotInfoSuccess = (state, action) => {
  return Object.assign({}, state, {
    liveShotInfo: action.liveShotInfo,
    lastUpdated: action.lastUpdated,
  });
};

const liveShotInfoFailure = (state, action) => {
  return Object.assign({}, state, {
    infoFailure: true,
  });
};

const liveshotNamesSuccess = (state, action) => {
  return Object.assign({}, state, {
    liveshotNamesList: action.liveshotNamesList,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIVESHOT_DATA:
      return liveShotInfoSuccess(state, action);
    case actionTypes.LIVE_SHOT_NAMES_SUCCESS:
      return liveshotNamesSuccess(state, action);
    case actionTypes.LIVE_SHOT_INFO_FAILURE:
      return liveShotInfoFailure(state, action);
    default:
      return state;
  }
};

export default reducer;

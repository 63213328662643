import axios from "../../axios";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../../store/actions/index";

export const fetchStudioTruckListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_STUDIOTRUCK_LIST,
    studioTruckList: lists,
  };
};

export const fetchStudioTruckList = (xApiKey) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/studioTruck/", {
        headers: { "x-api-key": xApiKey },
      })
      .then((res) => {
        dispatch(fetchStudioTruckListSuccess(res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response) {
          if (err.response.status === 403) {
            dispatch(actions.liveShotInfoFailure());
          }
        }
      });
  };
};

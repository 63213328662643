import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./LiveshotAndVenueNameModal.css";

const LiveshotAndVenueNameModal = (props) => {
  const { closeModal, liveShotName, liveshotNamesList, venueList, stId } =
    props;
  const [liveshotNames, setLivesshotNames] = useState("");
  const [venueName, setVenueName] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showVenueSuggestions, setShowVenueSuggestions] = useState(false);
  const [selectedLiveshotName, setSelectedLiveshotName] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState("");
  const [nameFieldDisable, setNameFieldDisable] = useState(false);
  const [venueFeildDisable, setVenueFeildDisable] = useState(false);
  useEffect(() => {
    if (liveshotNamesList) {
      setLivesshotNames(liveshotNamesList);
    }
  }, [liveshotNamesList, liveshotNames]);

  const studioNameArr = venueList
    .filter((list) => list.softDelete === false)
    .map((list) => {
      return list.studioName;
    });

  const studioList = studioNameArr.filter((list) => {
    return list != null;
  });

  const onVenueChange = (e) => {
    e.target.value ? setNameFieldDisable(true) : setNameFieldDisable(false);
    setVenueName(e.target.value);
    let value = e.target.value;
    if (value !== undefined) {
      const filSuggestion = studioList.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSuggestions(filSuggestion);
      setShowVenueSuggestions(true);
    }
  };

  const onLiveshotNameChange = (e) => {
    e.target.value ? setVenueFeildDisable(true) : setVenueFeildDisable(false);
    setSelectedLiveshotName(e.target.value);
    let value = e.target.value;
    if (value !== undefined) {
      const filSuggestion = liveshotNames.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSuggestions(filSuggestion);
      setShowSuggestions(true);
    }
  };

  const onSelectVenue = (e) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setVenueName(e.target.innerText);
    let value = e.target.innerText;
    let selectedStudio = venueList.filter(
      (list) => list.studioName === value && list.softDelete === false
    );
    if (selectedStudio.length > 0) {
      let Id = selectedStudio[0].stId;
      stId(Id);
      closeModal();
    }
  };
  let suggestionsListComponent = null;
  if (showSuggestions && selectedLiveshotName.trim() !== "") {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <li
                key={suggestion}
                onClick={(e) => {
                  liveShotName(e.target.innerText);
                  closeModal();
                }}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  let suggestionsVenueListComponent = null;
  if (showVenueSuggestions && venueName.trim() !== "") {
    if (filteredSuggestions.length) {
      let uniqueFilteredSuggestions = filteredSuggestions.filter(
        (item, pos) => {
          return filteredSuggestions.indexOf(item) === pos;
        }
      );
      uniqueFilteredSuggestions = uniqueFilteredSuggestions.sort(function (
        a,
        b
      ) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      suggestionsVenueListComponent = (
        <ul className="suggestions">
          {uniqueFilteredSuggestions.map((suggestion, index) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  onSelectVenue(e);
                  closeModal();
                }}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }
  return (
    <div className="popup-modal">
      <div className="modal-content">
        <div className="modal-body">
          <div className="liveshotName">
            <label>Select the LiveshotName</label> <br />
            <input
              type="text"
              disabled={nameFieldDisable}
              onChange={onLiveshotNameChange}
            />
            {suggestionsListComponent}
          </div>
          <h5>Or</h5>
          <div className="liveshotName">
            <label>Select the VenueName</label> <br />
            <input
              type="text"
              onChange={onVenueChange}
              disabled={venueFeildDisable}
            />
            {suggestionsVenueListComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // liveshotNamesList: state.liveshotReducer.liveshotNamesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveshotAndVenueNameModal);

import React, { useState } from "react";

import "./XApiKeyModal.css";

const XApiKeyModal = (props) => {
  const { closeXapiModal, xApiKey } = props;

  const [apiKey, setApiKey] = useState("");

  let email = process.env.REACT_APP_CR_DETAILS_KEY_EMAIL;
  let hrefMailTo = `mailto:${email}?subject=${"Request for Api Key"}`;
  let name = process.env.REACT_APP_CR_DETAILS_KEY_CONTACT;

  const onChangeXapiKey = (event) => {
    setApiKey(event.currentTarget.value);
  };
  const onSaveApiKey = () => {
    localStorage.setItem("x-api-key", apiKey);
    xApiKey(apiKey);
    closeXapiModal();
  };

  return (
    <div className="popup-modal">
      <div className="modal-content signal-msg-content-box">
        <div className="modal-header">
          {/* <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={props.closeXapiModal}
          >
            &times;
          </button> */}
        </div>
        <div className="modal-body signal-msg-body">
          <div>
            Email
            <a href={hrefMailTo}> {name} </a>
            to request an API Key to access control room details
          </div>
          <div className="apiKeyInput">
            <label>API Key</label>
            <input
              type="text"
              name="KeyInput"
              autoComplete="off"
              value={apiKey || ""}
              onChange={onChangeXapiKey}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={onSaveApiKey}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default XApiKeyModal;

export const splitSignalAndPhone = (pathName) => {
  var typicalPatt = /^(ifb|pl) [0-9a-z][0-9a-z]\d\d /i;
  var occasionalPatt = /^(ifb|pl) \d[0-9a-z]/i;
  var generalPatt = /^(ph)\d\d/i;
  var anotherPatt = /^(ifb|pl) (i|p)\d[0-9a-z][0-9a-z]/i;
  var elements = [];
  if (pathName !== null) {
    if (pathName.match(typicalPatt)) {
      elements.push(pathName.match(typicalPatt)[0].trim());
      elements.push(pathName.replace(typicalPatt, ""));
    } else if (pathName.match(occasionalPatt)) {
      elements.push(pathName.match(occasionalPatt)[0].trim());
      elements.push(pathName.replace(occasionalPatt, ""));
    } else if (pathName.match(generalPatt)) {
      elements.push(pathName.match(generalPatt)[0].trim());
      elements.push(pathName.replace(generalPatt, ""));
    } else if (pathName.match(anotherPatt)) {
      elements.push(pathName.match(anotherPatt)[0].trim());
      elements.push(pathName.replace(anotherPatt, ""));
    }
  }
  return elements;
};

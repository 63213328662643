import * as actionTypes from "../actions/actionTypes";

const initialState = {
  studioTruckList: [],
};

const fetchStudioTruckListSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckList: action.studioTruckList,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STUDIOTRUCK_LIST:
      return fetchStudioTruckListSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
